import { FC, useState, useContext, useEffect } from "react";
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import client from "../../api";
import { IOurPriceDataType } from "src/lib/constants/table-type";
import GlobalContext from "src/context/global-context";
import {
  NetworkDisconnectMsg,
  APISomethingWrongMsg,
  LIST_PAGE_SIZE,
} from "src/lib/constants";
import { getErrorMessage } from "../../lib/utils";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { useMutation } from "react-query";
import LanguageTabs from "src/components/our-prices//language-tabs";
import BusinessTabs from "src/components/our-prices/business-tabs";

const tabLists = [
  "Deposit",
  "Withdrawal",
  "Withdrawal of international fast money transfer KWIKPAY to an electronic wallet",
];

const FeeManagementPage: FC = () => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [lang, setLang] = useState("en");

  const [dataSource, setDataSource] = useState<IOurPriceDataType[]>([]);

  const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);

  const isMounted = useIsMounted();

  const { mutate: getOurPrices, isLoading } = useMutation(
    client.ourPrices.all,
    {
      onSuccess: (data) => {
        setDataSource(
          data.map((item, idx) => ({
            key: idx,
            ...item,
          }))
        );
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  useEffect(() => {
    if (isMounted) {
      getOurPrices({ lang });
    }
  }, [isMounted, lang]);


  const makeTabList = () => {
    const tabListsUI = tabLists.map((item, index) => (
      <Tab
        key={`tabs_${index}`}
        className={({ selected }) =>
          classNames(
            "h-full font-medium text-[14px] md:text-[16px] relative px-2 py-2 md:px-8 md:py-4 before:absolute before:left-0 before:bottom-0 before:h-0.5 before:bg-brand before:transition-all before:duration-300 before:ease-in-out",
            "ring-transparent ring-opacity-60 ring-offset-0 ring-offset-transparent focus:outline-none focus:ring-0",
            selected
              ? "text-primary bg-white rounded-t-[8px]"
              : "text-secondary bg-transparent"
          )
        }
      >
        {item}
      </Tab>
    ));
    return (
      <Tab.List className="flex flex-row items-center w-full h-[58px] md:h-[56px]">
        {tabListsUI}
      </Tab.List>
    );
  };

  return (
    <div className="w-full h-full bg-gray">
      <div className="w-full h-full flex-col p-2 sm:p-4">
        <div className="title-block flex mb-16">
          <p className="text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[37.5px] sm:leading-[48px]">
            Our Prices
          </p>
        </div>
        <LanguageTabs selected={lang} setSelected={setLang} />
        <Tab.Group
          selectedIndex={selectedTabIndex}
          onChange={(tabIdx: number) => setSelectedTabIndex(tabIdx)}
        >
          {makeTabList()}
          <Tab.Panels>
            <Tab.Panel className="border-0 focus:border-0 focus:ring-0 ring-0">
              <BusinessTabs
                lang={lang}
                dataSource={dataSource}
                setDataSource={setDataSource}
                isLoading={isLoading}
                setIsAlertOpened={setIsAlertOpened}
                setAlertText={setAlertText}
              />
            </Tab.Panel>
            <Tab.Panel className="border-0 focus:border-0 focus:ring-0 ring-0">
              <BusinessTabs
                lang={lang}
                dataSource={dataSource}
                setDataSource={setDataSource}
                isLoading={isLoading}
                setIsAlertOpened={setIsAlertOpened}
                setAlertText={setAlertText}
                isWithdrawal

              />
            </Tab.Panel>
            <Tab.Panel className="border-0 focus:border-0 focus:ring-0 ring-0">
              <BusinessTabs
                lang={lang}
                dataSource={dataSource}
                setDataSource={setDataSource}
                isLoading={isLoading}
                setIsAlertOpened={setIsAlertOpened}
                setAlertText={setAlertText}
                isKwickpay
              />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

export default FeeManagementPage;
