import { FC, useContext, useEffect, useState, useMemo } from "react";
/*eslint-disable */
import { useMutation } from "react-query";
import client from "../../api";
import { getErrorMessage } from "../../lib/utils";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { Form, Input, Table } from "antd";
import { NetworkDisconnectMsg, APISomethingWrongMsg } from "src/lib/constants";
import { IAddOurPriceDataType, IOurPriceDataType } from "src/lib/constants/table-type";
import { ColumnsType } from "antd/es/table";
import { EditIcon } from "src/components/icons/edit-icon";
import { CloseIcon } from "src/components/icons/close-icon";
import { CheckIcon } from "src/components/icons/check-icon";
import { DeleteIcon } from "../icons/delete-icon";
import { useModalAction } from "../modal-views/context";

interface IOurPricesTable {
  lang: string;
  dataSource: IOurPriceDataType[];
  isLoading: boolean;
  setDataSource: React.Dispatch<React.SetStateAction<IOurPriceDataType[]>>;
  isBusiness?: boolean;
  isWithdrawal?: boolean;
  isKwickpay?: boolean;
  editingKey?: any;
  setEditingKey?: any;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: IOurPriceDataType;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }}>
          <Input />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const OurPricesTable: FC<IOurPricesTable> = ({
  lang,
  isLoading,
  dataSource,
  setDataSource,
  isBusiness,
  isWithdrawal,
  isKwickpay,
  editingKey,
  setEditingKey,
}) => {
  const [form] = Form.useForm();
  const [isMobile, setIsMobile] = useState<boolean>(false);
  // const [editingKey, setEditingKey] = useState("");
  const [deleteKey, setDeleteKey] = useState("");
  const isEditing = (record: IOurPriceDataType) => record.key === editingKey;

  const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);
  const isMounted = useIsMounted();
  const { openModal, closeModal } = useModalAction();

  const filterByType = isWithdrawal
    ? "withdrawal"
    : isKwickpay
    ? "kwikpay"
    : "deposit";

  console.log("filterByType", filterByType);

  const filteredDataSource = useMemo(() => {
    return dataSource.filter(
      (item) =>
        item.user_type === (isBusiness ? "business" : "private") &&
        item.type === filterByType
    );
  }, [dataSource, isBusiness]);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 768 ? true : false);
  }

  const { mutate: updateOurPrices, isLoading: isUpdateLoading } = useMutation(
    client.ourPrices.update,
    {
      onSuccess: (data) => {
        setDataSource((prev) => {
          return prev.map((item) => {
            return item.id === data.id ? { key: item.key, ...data } : item;
          });
        });
        setEditingKey("");
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );
 
  const { mutate: addOurPrices, isLoading: isAddLoading } = useMutation(
    client.ourPrices.add,
    {
      onSuccess: (data) => {
        console.log(data,"DATDD55")
        setDataSource((prev: any) => {
          // Remove any temporary row marked with isNew, then add the new row from API
          return [...prev.filter((item: any) => !item.isNew), data];
        })
        setEditingKey("");
        form.resetFields();
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );
 
  
 
  useEffect(() => {
    const timer = setTimeout(() => {
      if (isMounted) {
        console.log("called");
        // getFees(searchParams);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [isMounted]);

     const { mutate: getOurPrices, isLoading: loading } = useMutation(
        client.ourPrices.all,
        {
          onSuccess: (data) => {
            setDataSource(
              data.map((item, idx) => ({
                key: idx,
                ...item,
              }))
            );
          },
          onError: (error: any) => {
            if (error.code === "ERR_NETWORK") {
              setAlertText(NetworkDisconnectMsg);
            } else {
              if (error.response) {
                setAlertText(getErrorMessage(error.response.data));
              } else {
                setAlertText(APISomethingWrongMsg);
              }
            }
            setIsAlertOpened(true);
          },
        }
      );



  const edit = (record: Partial<IOurPriceDataType> & { key: React.Key }) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key as string);
  };
   
  const onCallback = (bSuccess: boolean) => {
    form.resetFields();

    bSuccess && getOurPrices({lang});
  };

  const Delete = (record: Partial<IOurPriceDataType> & { key: React.Key }) => {
      openModal("PRICE_DELETE_CONFIRM_VIEW", {
        id: record?.id,
        callback: onCallback,
      });
  //   console.log(record,"KEY")
  //  form.setFieldValue("data",(prev: any) => prev.filter((item: any) => item.id !== record.id) )
  }

  const cancel = (record: any) => {
    setEditingKey("");
    form.resetFields();
    if (record.isNew) {
      setDataSource((prev) => prev.filter((item) => item.key !== record.key));
    }
  };

  const save = async (id: number) => {
    try {
      const row = (await form.validateFields()) as IOurPriceDataType;
      updateOurPrices({ ...row, id });
      return;
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };
  
  const addData = async (record: any) => {
    try {
      const row: any = (await form.validateFields()) as IAddOurPriceDataType;
      console.log("Validate Failed: 1", row)
      const keys = Object.keys(row);
      const firstUndefinedKey = keys.find(key => row[key] === undefined);
      if(firstUndefinedKey){
        setAlertText(`The field ${firstUndefinedKey} is required.`);
        setIsAlertOpened(true);
        return;
      }
      const data: any = {
        destination: row.destination,
        fee: row.fee,
        lang: record.lang,
        limit: row.limit,
        period: row.period,
        type: record.type,
        user_type: record.user_type,
      }
      console.log("Validate Failed:2")
      addOurPrices(data);



      return;
    } catch (errInfo) {
      console.log("Validate Failed:3")
      console.log("Validate Failed:", errInfo);

    }
     
  }
  const kwkColumns = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Commission rate
        </p>
      ),
      dataIndex: "fee",
      key: "fee",
      className: "w-auto",
      editable: true,
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Transaction limit (minimum/maximum)
        </p>
      ),
      dataIndex: "limit",
      key: "limit",
      className: "w-auto",
      editable: true,
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Period
        </p>
      ),
      dataIndex: "period",
      key: "period",
      className: "w-auto",
      editable: true,
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Action
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      align: "right" as const,
      render: (_: any, record: IOurPriceDataType) => {
        const editable = isEditing(record);
        return editable ? (
          <div className="flex flex-row gap-2 justify-end">
            <button
              className="w-[30px] h-[30px] flex items-center justify-start"
              onClick={() => cancel(record)}
            >
              <CloseIcon className="w-[20px] h-[20px] text-secondary" />
            </button>
            <button
              className="w-[30px] h-[30px] flex items-center justify-start"
              onClick={() => record?.isNew ? addData(record): save(record.id)}
            >
              <CheckIcon className="w-[20px] h-[20px] text-primary" />
            </button>
          </div>
        ) : (
          <div className="flex flex-row gap-2 justify-end">
             <button
              className="w-[30px] h-[30px] flex items-center justify-start"
              onClick={() => Delete(record)}
            >
              <DeleteIcon className="w-[20px] h-[20px] text-primary" />
            </button>
            <button
              className="w-[30px] h-[30px] flex items-center justify-start"
              onClick={() => edit(record)}
            >
              <EditIcon className="w-[20px] h-[20px] text-primary" />
            </button>
          </div>
        );
      },
    },
  ];

  const columns = isKwickpay
    ? kwkColumns
    : [
        {
          title: () => (
            <p className="font-manrope text-secondary text-[14px] font-medium">
              Deposit to a Fastoo multi-currency e-wallet account
            </p>
          ),
          dataIndex: "destination",
          key: "destination",
          className: "w-auto",
          editable: true,
        },
        ...kwkColumns,
      ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: IOurPriceDataType) => ({
        record,
        inputType: "number",
        dataIndex: col.dataIndex,
        title: "",
        editing: isEditing(record),
      }),
    };
  });

  const mobileColumns: ColumnsType<IOurPriceDataType> = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Info
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_, record: { key: React.Key }) => {
        const selData = dataSource.filter((item) => item.key === record.key)[0];

        return (
          <div className="w-full flex flex-col gap-4 items-start justify-between">
            {!isKwickpay && (
              <div className="w-full flex flex-row items-center justify-between gap-2">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Deposit to a Fastoo multi-currency e-wallet account
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium">
                  {selData.destination}
                </p>
              </div>
            )}
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                Commission rate
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {selData.fee}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium">
                Transaction limit (minimum/maximum)
              </p>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {selData.limit}
              </p>
            </div>
            <div className="w-full flex flex-row items-center justify-between gap-2">
              <p className="text-secondary text-[14px] font-manrope font-medium invisible">
                Edit
              </p>
            </div>
          </div>
        );
      },
    },
  ];
 console.log(filteredDataSource,"FILTERED DATA SOURCE")
 console.log(dataSource,"DATA SOURCE")
  return (
    <div className="flex flex-col w-full bg-white rounded-[12px] p-4">
      {isMobile ? (
        <Table
          loading={isLoading}
          showSorterTooltip={false}
          columns={mobileColumns}
          dataSource={filteredDataSource}
          pagination={false}
        />
      ) : (
        <Form form={form} component={false}>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            loading={isLoading}
            showSorterTooltip={false}
            columns={mergedColumns}
            dataSource={filteredDataSource}
            pagination={false}
            rowClassName="editable-row"
          />
        </Form>
      )}
    </div>
  );
};

export default OurPricesTable;
