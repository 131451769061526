import cn from "classnames";
import routes from "../config/routes";
import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLogout } from "../hooks/user";
import useAuth from "../hooks/use-auth";
import { makeSidebarMenu } from "src/lib/utils";
import GlobalContext from "src/context/global-context";

interface NavLinkProps {
  handleToggle: (index: any) => void;
  subMenuOpen: boolean | null;
  index: any;
  href: string;
  setCollapse?: any;
  title: string;
  icon: React.ReactNode;
  arrowUpIcon: React.ReactNode;
  arrowDownIcon: React.ReactNode;
  activeIcon: React.ReactNode;
  isCollapse?: boolean;
  subMenu: any;
}

function NavLink({
  subMenuOpen,
  handleToggle,
  index,
  href,
  setCollapse,
  icon,
  arrowUpIcon,
  arrowDownIcon,
  activeIcon,
  title,
  isCollapse,
  subMenu,
}: NavLinkProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const { unauthorize } = useAuth();
  const { mutate: logout } = useLogout();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);
    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(false);
    if (window.innerWidth < 640) {
      setIsMobile(true);
    }
  }

  const onClick = (subHref: string) => {
    isMobile && setCollapse && setCollapse(false);
    handleToggle(index);
    if (href === routes.logout) {
      unauthorize();
      logout();
      navigate(routes.home);
    } else {
      navigate(subHref ? subHref : href);
    }
  };

  const checkActiveStatus = (subHref: string) => {
    return location.pathname.includes(subHref ? subHref : href);
  };

  return (
    <div key={index}>
      <a
        onClick={subMenu ? () => handleToggle(index) : () => onClick("")}
        className={cn(
          checkActiveStatus("") || subMenuOpen === index ? "bg-active" : "",
          "my-0.5 flex justify-between gap-1 px-4 py-4 rounded-[10px] md:pl-7 md:px-3 xs:px-6 sm:my-1 sm:gap-1.5 sm:px-7 lg:gap-2 xl:my-0.5 cursor-pointer w-[calc(100%-20px)] mx-[10px]"
        )}
      >
        <span className="flex justify-between">
          <span
            className={cn(
              "flex flex-shrink-0 items-center justify-start",
              isCollapse
                ? "w-[20px] xl:w-auto"
                : "w-auto xl:w-[20px] -translate-x-[10px]"
            )}
          >
            {checkActiveStatus("") || subMenuOpen === index ? activeIcon : icon}
          </span>
          <span
            className={cn(
              "font-manrope font-medium text-[14px] dark:text-light-400 transition-all ease-out duration-300",
              isCollapse
                ? "inline-flex xl:opacity-0 opacity-100"
                : "opacity-0 xl:inline-flex xl:opacity-100",
              checkActiveStatus("") || subMenuOpen === index
                ? "text-primary"
                : "text-secondary"
            )}
          >
            {title}
          </span>
        </span>
        <span>
          {checkActiveStatus("") || subMenuOpen === index
            ? arrowUpIcon
            : arrowDownIcon}
        </span>
      </a>
      {subMenu && (
        <ul
          className={cn(
            checkActiveStatus("") || subMenuOpen === index ? "flex" : "hidden",
            "my-0.5 flex-col gap-1 px-4 py-4 rounded-[10px] xs:px-6 sm:my-1 sm:gap-1.5 sm:px-7 lg:gap-2 xl:my-0.5 cursor-pointer w-[calc(100%-20px)] mx-[10px]"
          )}
        >
          {subMenu &&
            subMenu.map((subMenuitem: any, index: number) => {
              return (
                <li key={index}>
                  <a
                    className={
                      checkActiveStatus(subMenuitem.link)
                        ? "text-primary"
                        : "text-secondary"
                    }
                    onClick={() => onClick(subMenuitem.link)}
                  >
                    {subMenuitem.title}
                  </a>
                </li>
              );
            })}
        </ul>
      )}
    </div>
  );
}

export default function Sidebar({
  isCollapse,
  setCollapse,
  className = "sm:flex fixed bottom-0 z-20 pt-16",
}: {
  isCollapse?: boolean;
  setCollapse?: any;
  className?: string;
}) {
  const location = useLocation();
  const { getCurrrentUser } = useAuth();

  const permissions = makeSidebarMenu(getCurrrentUser().permissions);
  console.log(permissions,"PERSMISSION")

  const [subMenuOpen, setSubMenuOpen] = useState(false);

  const handleToggle = (index: any) => {
    // if (subMenuOpen === index) {
    //   setSubMenuOpen(false);
    // } else {
    setSubMenuOpen(index);
    // }
  };

  return (
    <aside
      className={cn(
        "h-full flex-col justify-between overflow-y-auto border-r border-light-400 bg-light text-dark-900 dark:border-0 dark:bg-dark-200 transition-all duration-300 sm:pt-[70px]",
        isCollapse
          ? "sm:w-60 xl:w-[75px] w-60 left-0"
          : "sm:w-[75px] xl:w-72 w-72 -left-72 sm:left-0",
        className
      )}
    >
      <div className="flex h-full w-full flex-col overflow-y-auto scrollbar-hide">
        <nav className="flex flex-col mt-2 mb-20">
          {permissions &&
            permissions.map((item, index) => {
              return (
                <>
                  {item && (
                    <NavLink
                      handleToggle={handleToggle}
                      subMenuOpen={subMenuOpen}
                      index={index}
                      key={`navbar_${index}`}
                      title={item.title}
                      setCollapse={setCollapse}
                      href={item.link}
                      isCollapse={isCollapse}
                      icon={item.icon}
                      arrowUpIcon={item.arrowUpIcon}
                      arrowDownIcon={item.arrowDownIcon}
                      activeIcon={item.activeIcon}
                      subMenu={item.subMenu}
                    />
                  )}
                </>
              );
            })}
        </nav>
      </div>
    </aside>
  );
}
