import {
    FC,
    useRef,
    useCallback,
    useState,
    useEffect,
    useContext,
  } from "react";
  import Button from "../ui/button";
  import { useNavigate } from "react-router-dom";
  import { CloseIcon } from "../icons/close-icon";
  import { useModalAction, useModalState } from "./context";
  import { useMutation } from "react-query";
  import client from "../../api";
  import { getErrorMessage } from "../../lib/utils";
  import {
    APISomethingWrongMsg,
    NetworkDisconnectMsg,
    SUCCESS_MODAL_TYPE,
  } from "src/lib/constants";
  import GlobalContext from "src/context/global-context";
  
  const PriceDeleteConfirmModal = () => {
    const { closeModal, openModal } = useModalAction();
    const { data: params } = useModalState();
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);
  
    useEffect(() => {
      updateWindowSize();
      window.addEventListener("resize", updateWindowSize);
      return () => window.removeEventListener("resize", updateWindowSize);
    }, []);
  
    function updateWindowSize() {
      setIsMobile(window.innerWidth < 640 ? true : false);
    }
    console.log(params.type);

 
    const { mutate: deleteOurPrices, isLoading } = useMutation(
        client.ourPrices.delete,
      {
        onSuccess: (data) => {
          params.callback(true);
          closeModal();
          // window.location.reload();
        },
        onError: (error: any) => {
          console.log(error,"DELETE 1")
          if (error.code === "ERR_NETWORK") {
            setAlertText(NetworkDisconnectMsg);
          } else {
            if (error.response) {
              console.log("DELETE 2")
              setAlertText(getErrorMessage(error.response.data));
            } else {
              console.log("DELETE 3")
              setAlertText(APISomethingWrongMsg);
            }
          }
          setIsAlertOpened(true);
        },
      }
    );
  
    const onProcess = () => {
        deleteOurPrices(params.id);
    };
  
    return (
      <div
        className={`${
          isMobile
            ? "relative rounded-t-[22px] overflow-y-auto"
            : "rounded-[12px]"
        } w-full max-h-[60%] md:h-full md:w-[469px] px-4 py-10 bg-white gap-4`}
      >
        <button
          aria-label="Close panel"
          onClick={closeModal}
          className="md:hidden absolute top-5 text-primary right-5"
        >
          <CloseIcon className="h-6 w-6 text-primary" />
        </button>
  
        <div className="h-full w-full flex flex-col items-center justify-center gap-4">
          <p className="font-manrope text-[20px] font-medium text-primary text-center px-10">
            Are you sure you want to delete ?
          </p>
          <div className="w-full flex flex-row items-center justify-between gap-2 mt-2">
            <Button
              variant="solid"
              className="w-full rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] h-[60px] bg-secondary hover:bg-primary text-white"
              onClick={onProcess}
              isLoading={isLoading}
            >
              Yes, I'm sure
            </Button>
  
            <Button
              variant="solid"
              className="w-full rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] h-[60px] text-white bg-error hover:bg-opacity-50"
              onClick={closeModal}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
  };
  
  export default PriceDeleteConfirmModal;
  