import { useState, useEffect, FC, ReactNode } from "react";
import GlobalContext from "./global-context";

export default function GlobalContextWrapper({
  children,
}: React.PropsWithChildren<{}>) {
  const [isAlertOpened, setIsAlertOpened] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [invoiceFromPage, setInvoiceFromPage] = useState("");
  const [key, setKey] = useState(0);

  return (
    <GlobalContext.Provider
      value={{
        isAlertOpened,
        setIsAlertOpened,
        alertText,
        setAlertText,
        key,
        setKey
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}
