import React, { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";

const LineChart = (Props: any) => {
  const chartRef = useRef(null);
  // console.log(Props, "DTATATA");

  const selectedYear = Props?.year;
  const yearData = Props?.data;
  // console.log(selectedYear, "kjvbfbvk");

  useEffect(() => {
    // Ensure data exists before proceeding
    if (!yearData || !selectedYear || !yearData[selectedYear]) {
      console.warn("Invalid or missing data for the selected year.");
      return;
    }
    const monthsData = yearData[selectedYear];
    const monthKeys = Object.keys(monthsData);
    console.log(monthKeys, "kkk");
    let maxValue = 0;
    // Extracting individual and business data dynamically
    const individualData = monthKeys.map((month) => {
      const value = monthsData[month]?.individual || 0;
      maxValue = Math.max(maxValue, value);
      return value;
    });
    const businessData = monthKeys.map((month) => {
      const value = monthsData[month]?.business || 0;
      maxValue = Math.max(maxValue, value);
      return value;
    });

    // console.log(maxValue,"MAXXX")
    let interval = Math.ceil(maxValue / 5); // Ensure intervals are whole numbers

    const options = {
      chart: {
        height: 350,
        type: "bar",
        stacked: false,
        toolbar: {
          show: false,
        },
        animations: {
          enabled: true,
          easing: "easeinout",
          speed: 800,
        },
      },
      dataLabels: {
        enabled: false, // Hide values on bars
      },
      colors: ["#FF1654", "#247BA0"],
      series: [
        {
          name: "Individual",
          data: individualData,
        },
        {
          name: "Business",
          data: businessData,
        },
      ],
      stroke: {
        width: [4, 4],
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          columnWidth: "40%", // Reduce to make bars wider (default ~70%)
          barHeight: "80%",
        },
      },
      xaxis: {
        categories: monthKeys,
        labels: {
          rotate: -45,
        },
      },
      yaxis: [
        {
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: "#FF1654",
          },
          labels: {
            style: {
              colors: "#FF1654",
            },
          },
          title: {
            text: "Monthly Active Users",
            style: {
              color: "#FF1654",
            },
          },
          min: 0,
          max: Math.round(maxValue),
          // tickAmount: Math.floor(maxValue / interval),
          forceNiceScale: true,
        },
      ],
      tooltip: {
        enabled: true,
        shared: true,
        intersect: false,
        theme: "dark",
        y: {
          formatter: function (value: any) {
            return value !== "-" ? `${value} Users` : "No data";
          },
        },
      },
      grid: {
        borderColor: "#e7e7e7",
        strokeDashArray: 4,
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
      },
      responsive: [
        {
          breakpoint: 600,
          options: {
            chart: {
              height: 300,
            },
            xaxis: {
              labels: {
                rotate: -45,
              },
            },
          },
        },
      ],
    };
    const chart = new ApexCharts(chartRef.current, options);
    chart.render();
    // Cleanup function to destroy the chart when the component unmounts
    return () => {
      chart.destroy();
    };
  }, [yearData, selectedYear]); // Added dependencies

  return <div style={{height: 500}} ref={chartRef} />;
};

export default LineChart;
