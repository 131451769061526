import { FC, useContext, useEffect, useState, MouseEvent, useRef } from "react";
import { useModalAction } from "../../components/modal-views/context";
import { useMutation } from "react-query";
import client from "../../api";
import { getCurrencyInfo, getErrorMessage } from "../../lib/utils";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { DateRangePicker } from "rsuite";
import isBefore from "date-fns/isBefore";
import { DateRange } from "rsuite/esm/DateRangePicker/types";
import moment from "moment";
import { Spin } from "antd";
import { NetworkDisconnectMsg, APISomethingWrongMsg } from "src/lib/constants";
import { ActiveClientCount, AssetBalance } from "src/api/types";
import Chart from "src/components/dashboard/chart";
import LineChart from "src/components/dashboard/lineChart";

const DashboardPage: FC = () => {
  const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);
  const isMounted = useIsMounted();
  const [balances, setBalances] = useState<AssetBalance[]>([]);
  const [activeCount, setActiveCount] = useState<ActiveClientCount>();
  const [totalUsers, setTotalUsers] = useState<any>();

  const [totalBalance, setTotalBalance] = useState(0);
  const [assetNames, setAssetNames] = useState<string[]>([]);
  const [assetBalances, setAssetBalances] = useState<number[]>([]);
  const [balanceTab, setBalanceTab] = useState<string | null>(null);
  const [graphData, setGraphData] = useState<any>();

  const [dateRangeValue, setDateRangeValue] = useState<DateRange | null>(null);
  const [startDataDate, setStartDataDate] = useState<any>(null);

  const [selectedYear, setSelectedYear] = useState<string>(
    new Date().getFullYear().toString().toString()
  );
  const selectYearRef = useRef(new Date().getFullYear().toString().toString());

  const [yearOptions, setYearOptions] = useState<string[]>([]);

  const now = new Date();
  const prevMonth = new Date(now.setMonth(now.getMonth() - 1));

  const [startDate, endDate] = dateRangeValue || [];

  const { mutate: getUserBalances, isLoading } = useMutation(
    client.dashboard.getBalances,
    {
      onSuccess: (data) => {
        console.log(data);

        setBalanceTab(data.balances[0].asset_code);

        setAssetNames(
          data.balances.map((item) => item.asset_code.toUpperCase())
        );
        setAssetBalances(data.balances.map((item) => item.balance_usd));

        setBalances(data.balances);
        setActiveCount(data.activeClientsCount);
        setTotalUsers(data?.totalRegisteredUsers);
        setTotalBalance(data.total_balances_usd);
        setGraphData(data?.graphData);
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const handleBalanceTab = (assetCode: string) => {
    setBalanceTab(assetCode);
  };

  const { mutate: uploadStartDate } = useMutation(
    client.dashboard.getStartDate,
    {
      onSuccess: (data: any) => {
        if (data && data.start_day) setStartDataDate(data?.start_day);
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  useEffect(() => {
    if (isMounted) {
      getUserBalances();
      uploadStartDate({});
    }
  }, [isMounted]);

  const { mutate: exportDashboard } = useMutation(client.dashboard.export, {
    onSuccess: (data) => {
      setAlertText("Export success");
      setIsAlertOpened(true);
    },
    onError: (error: any) => {
      if (error.code === "ERR_NETWORK") {
        setAlertText(NetworkDisconnectMsg);
      } else {
        if (error.response) {
          setAlertText(getErrorMessage(error.response.data));
        } else {
          setAlertText(APISomethingWrongMsg);
        }
      }
      setIsAlertOpened(true);
    },
  });

  const exportBtn = async () => {
    try {
      setTimeout(() => {
        exportDashboard({
          start_date: startDate
            ? moment(startDate).format("yyyy-MM-DD")
            : undefined,
          end_date: endDate ? moment(endDate).format("yyyy-MM-DD") : undefined,
        });
      }, 300);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleYearChange = (e: any) => {
    selectYearRef.current = e.target.value; // Updates ref value
    console.log("Selected Year:", selectYearRef.current);
  };

  useEffect(() => {
    if (graphData) {
      let values = Object.keys(graphData);
      console.log(values, "VAL");
      values = values.sort((a: any, b: any) => {
        if (a > b) {
          return -1;
        } else if (a < b) {
          return 1;
        } else {
          return 0;
        }
      });
      setYearOptions(values);
    }
  }, [graphData]);

  console.log(totalUsers, "total");

  const agency_statuses = [
    {
      label: "Unverified Agency",
      key: "unverified_agency",
      bgColor: "#bdbdbd",
    },
    { label: "Rejected Agency", key: "rejected_agency", bgColor: "#d73030" },
    {
      label: "Recrossing Agency",
      key: "recrossing_agency",
      bgColor: "#673ab7",
    },
    { label: "Pending Agency", key: "pending_agency", bgColor: "#fdd540" },
    { label: "Approval Agency", key: "approved_agency", bgColor: "#44b402" },
  ];
  const aml_statuses = [
    { label: "Unverified Users", key: "unverified_users", bgColor: "#bdbdbd" },
    { label: "Rejected Users", key: "rejected_users", bgColor: "#d73030" },
    { label: "Recrossing Users", key: "recrossing_users", bgColor: "#673ab7" },
    { label: "Pending Users", key: "pending_users", bgColor: "#fdd540" },
    { label: "Approval Users", key: "approved_users", bgColor: "#44b402" },
  ];

  const kyc_statuses = [
    { label: "Unverified Kyc", key: "unverified_kyc", bgColor: "#bdbdbd" },
    { label: "Rejected Kyc", key: "rejected_kyc", bgColor: "#d73030" },
    { label: "Recrossing Kyc", key: "recrossing_kyc", bgColor: "#673ab7" },
    { label: "Pending Kyc", key: "pending_kyc", bgColor: "#fdd540" },
    { label: "Approval Kyc", key: "approved_kyc", bgColor: "#44b402" },
  ];

  return (
    <div className="w-full h-full bg-gray">
      <div className="w-full h-full flex-col p-2 sm:p-4">
        <p className="text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[37.5px] sm:leading-[48px]">
          Dashboard
        </p>{" "}
        <div className="mt-4 flex items-center justify-end w-full mb-4">
          <div className="flex gap-2">
            <DateRangePicker
              format="dd/MM/yyyy"
              size="lg"
              block
              placement="bottomEnd"
              style={{ width: "308px" }}
              value={dateRangeValue}
              onChange={setDateRangeValue}
              shouldDisableDate={(date) => {
                console.log(date);
                const dataSet = new Date(startDataDate);
                dataSet.setDate(dataSet.getDate() - 1);
                return isBefore(date, new Date(dataSet));
              }}
              defaultCalendarValue={[prevMonth, now]}
              ranges={[]}
            />

            <button
              type="button"
              onClick={() => exportBtn()}
              className="button button--type3 button--gap30"
            >
              Export Excel
              <span className="btn-icon btn-icon--fill">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.79961 1.8002L12.6996 5.4002M8.79961 1.8002L5.19961 5.4002M8.79961 1.8002L8.79961 13.2002M15.9996 8.4002V16.2002L1.59961 16.2002L1.59961 8.4002"
                    stroke="#232800"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  ></path>
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div className="relative flex flex-col w-full items-center justify-between bg-white rounded-[12px] mt-2 sm:mt-4 p-4 sm:p-6 gap-4">
          {isLoading && (
            <Spin className="absolute inset-0 z-40 flex items-center justify-center rounded-[12px] bg-opacity-80 backdrop-blur-sm" />
          )}
          <div className="flex flex-col w-full gap-4">
            <div className="flex flex-row gap-2 items-center">
              <p className="font-manrope text-[18px] font-bold text-primary leading-[16px]">
                Balance
              </p>
              <p className="font-manrope text-[14px] font-light text-secondary leading-[16px]">
                {`Total Balance: $${totalBalance}`}
              </p>
            </div>
            <div className="w-full grid gap-3 grid-cols-12">
              <div className="lg:col-span-6 col-span-12">
                <div className="grid grid-cols-12 gap-4 mb-4">
                  {balances.map((item, key) => (
                    <div
                      key={`asset-${key}`}
                      className="group md:col-span-6 col-6 h-[48px] flex flex-row items-center justify-between px-4 shadow-currency rounded-[10px] cursor-pointer hover:bg-light-400"
                      onClick={() => handleBalanceTab(item.asset_code)}
                    >
                      <div className="flex flex-row gap-2 items-center">
                        <div className="w-[24px] h-[24px]">
                          {getCurrencyInfo(item.asset_code.toUpperCase()).icon}
                        </div>
                        <p className="font-manrope text-[12px] font-medium text-primary leading-[16px]">
                          {item.asset_code.toUpperCase()}
                        </p>
                      </div>
                      <p className="font-manrope text-[14px] font-medium text-primary leading-[16px]">
                        {item.balance}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="lg:col-span-6 col-span-12">
                {!isLoading && (
                  <Chart data={balances} currentBalanceItemName={balanceTab!} />
                )}
              </div>
              <div className="col-span-12">
                <div className="grid gap-3 grid-cols-12 items-center">
                  {/* <div className="md:col-span-5 col-span-12"> */}

                  {/* <div className="py-2">
                      <div className="pb-1 font-bold">
                        Active users in:{" "}
                        <strong>{activeCount?.previousMonth?.month}</strong>{" "}
                      </div>
                      <ul className="list-none pl-0 mb-0 grid gap-4 grid-cols-12">
                        <li className="group md:col-span-6  col-span-12 h-[48px] flex flex-row items-center justify-between px-4 shadow-currency rounded-[10px] cursor-pointer hover:bg-light-400">
                          <div className="flex items-center gap-3 text-left ">
                            <div className="h-[30px] w-[30px] flex items-center justify-center bg-[#c3e600] rounded-full">
                              {userIcon}
                            </div>
                            <span className="">Business : </span>
                          </div>
                          <div> {activeCount?.previousMonth?.business}</div>
                        </li>
                        <li className="group md:col-span-6  col-span-12 h-[48px] flex flex-row items-center justify-between px-4 shadow-currency rounded-[10px] cursor-pointer hover:bg-light-400">
                          <div className="flex items-center gap-3 text-left ">
                            <div className="h-[30px] w-[30px] flex items-center justify-center bg-[#c3e600] rounded-full">
                              {userIcon}
                            </div>
                            <span className="">Individual : </span>{" "}
                          </div>
                          <div> {activeCount?.previousMonth?.individual}</div>
                        </li>
                      </ul>
                    </div> */}
                  {/* <div className="py-2">
        <div className="pb-1 font-bold">
          Active users in:{" "}
          <strong>{activeCount?.currentMonth?.month}</strong>
        </div>
        <ul className="list-none pl-0 mb-0 grid gap-4 grid-cols-12">
          <li className="group md:col-span-6  col-span-12 h-[48px] flex flex-row items-center justify-between px-4 shadow-currency rounded-[10px] cursor-pointer hover:bg-light-400">
            <div className="flex items-center gap-3 text-left ">
              <div className="h-[30px] w-[30px] flex items-center justify-center bg-[#c3e600] rounded-full">
                {userIcon}
              </div>
              <span className="">Business : </span>
            </div>{" "}
            <div>{activeCount?.currentMonth?.business}</div>
          </li>
          <li className="group md:col-span-6  col-span-12 h-[48px] flex flex-row items-center justify-between px-4 shadow-currency rounded-[10px] cursor-pointer hover:bg-light-400">
            <div className="flex items-center gap-3 text-left ">
              <div className="h-[30px] w-[30px] flex items-center justify-center bg-[#c3e600] rounded-full">
                {userIcon}
              </div>
              <span className="">Individual : </span>{" "}
            </div>
            <div> {activeCount?.currentMonth?.individual}</div>
          </li>
        </ul>
      </div> */}
                  {/* </div> */}
                  <div className=" col-span-12">
                    <div className="text-right grid gap-3 grid-cols-12">
                      <div className="left col-span-12">
                        <div className="py-1">
                          <div className="pb-1 font-bold text-left">
                            Total Individual Registered Users :{" "}
                            {/* <span className="text-[#c5a401]">
                              {" "}
                            </span> */}
                          </div>
                          <ul className="list-none pl-0 mb-0 grid gap-4 grid-cols-12">
                            <li className="group md:col-span-3  sm:col-span-6 col-span-12 h-[48px] flex flex-row items-center justify-between px-4 shadow-currency rounded-[10px] cursor-pointer hover:bg-light-400">
                              <div className="flex items-center gap-3 text-left ">
                                <div className="h-[30px] w-[30px] flex items-center justify-center bg-[#c3e600] rounded-full">
                                  {userIcon}
                                </div>
                                <span className="">Total :</span>{" "}
                              </div>
                              <div>
                                {" "}
                                {totalUsers?.individual?.total}
                              </div>
                            </li>
                            <li className="group md:col-span-3  sm:col-span-6 col-span-12 h-[48px] flex flex-row items-center justify-between px-4 shadow-currency rounded-[10px] cursor-pointer hover:bg-light-400">
                              <div className="flex items-center gap-3 text-left ">
                                <div className="h-[30px] w-[30px] flex items-center justify-center bg-[#c3e600] rounded-full">
                                  {userIcon}
                                </div>
                                <span className="">Verified :</span>{" "}
                              </div>
                              <div>
                                {" "}
                                {totalUsers?.individual?.verified}
                              </div>
                            </li>
                            <li className="group md:col-span-3  sm:col-span-6 col-span-12 h-[48px] flex flex-row items-center justify-between px-4 shadow-currency rounded-[10px] cursor-pointer hover:bg-light-400">
                              <div className="flex items-center gap-3 text-left ">
                                <div className="h-[30px] w-[30px] flex items-center justify-center bg-[#c3e600] rounded-full">
                                  {userIcon}
                                </div>
                                <span className="">Unverified :</span>{" "}
                              </div>
                              <div>
                                {" "}
                                {totalUsers?.individual?.unverified}
                              </div>
                            </li>
                          </ul>
                        </div>
                        {/* <div className="py-1">
                          <div className="grid gap-3 grid-cols-12">
                            <div className="md:col-span-4 sm:col-span-6 col-span-12 py-1">
                              <div className="pb-1 font-bold text-left">
                                Agency Status
                              </div>
                              <ul className="list-none pl-0 mb-0 ">
                                {agency_statuses.map(
                                  ({ label, key, bgColor }) => (
                                    <li className="group my-1 h-[48px] flex flex-row items-center justify-between px-4 shadow-currency rounded-[10px] cursor-pointer hover:bg-light-400">
                                      <div className="flex items-center gap-3 text-left ">
                                        <div
                                          style={{ background: bgColor }}
                                          className={` h-[30px] w-[30px] text-white flex items-center justify-center rounded-full`}
                                        >
                                          {userIcon}
                                        </div>
                                        <span className="">{label} :</span>{" "}
                                      </div>
                                      <div>
                                        {" "}
                                        {
                                          totalUsers?.individual?.agency_status[
                                            key
                                          ]
                                        }
                                      </div>
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                            <div className="md:col-span-4 sm:col-span-6 col-span-12 py-1">
                              <div className="pb-1 font-bold text-left">
                                AML Status
                              </div>
                              <ul className="list-none pl-0 mb-0 ">
                                {aml_statuses.map(({ label, key, bgColor }) => (
                                  <li className="group my-1 h-[48px] flex flex-row items-center justify-between px-4 shadow-currency rounded-[10px] cursor-pointer hover:bg-light-400">
                                    <div className="flex items-center gap-3 text-left ">
                                      <div
                                        style={{ background: bgColor }}
                                        className="h-[30px] w-[30px] flex items-center justify-center bg-[#c3e600] rounded-full  text-white"
                                      >
                                        {userIcon}
                                      </div>
                                      <span className="">{label} :</span>{" "}
                                    </div>
                                    <div>
                                      {" "}
                                      {totalUsers?.individual?.aml_status[key]}
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <div className="md:col-span-4 sm:col-span-6 col-span-12 py-1">
                              <div className="pb-1 font-bold text-left">
                                Kyc Status
                              </div>
                              <ul className="list-none pl-0 mb-0 ">
                                {kyc_statuses.map(({ label, key, bgColor }) => (
                                  <li className="group my-1 h-[48px] flex flex-row items-center justify-between px-4 shadow-currency rounded-[10px] cursor-pointer hover:bg-light-400">
                                    <div className="flex items-center gap-3 text-left ">
                                      <div
                                        style={{ background: bgColor }}
                                        className="h-[30px] w-[30px] flex items-center justify-center bg-[#c3e600] rounded-full  text-white"
                                      >
                                        {userIcon}
                                      </div>
                                      <span className="">{label} :</span>{" "}
                                    </div>
                                    <div>
                                      {" "}
                                      {totalUsers?.individual?.kyc_status[key]}
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div> */}
                        <div className="py-1">
                          <div className="pb-1 font-bold text-left">
                            {" "}
                            Total Business Registered Users :{" "}
                          </div>
                          <ul className="list-none pl-0 mb-0 grid gap-4 grid-cols-12">
                            <li className="group md:col-span-3 sm:col-span-6  col-span-12 h-[48px] flex flex-row items-center justify-between px-4 shadow-currency rounded-[10px] cursor-pointer hover:bg-light-400">
                              <div className="flex items-center gap-3 text-left ">
                                <div className="h-[30px] w-[30px] flex items-center justify-center bg-[#c3e600] rounded-full">
                                  {userIcon}
                                </div>
                                <span className="">Total :</span>{" "}
                              </div>
                              <div>{totalUsers?.business?.total}</div>
                            </li>
                            <li className="group md:col-span-3  sm:col-span-6 col-span-12 h-[48px] flex flex-row items-center justify-between px-4 shadow-currency rounded-[10px] cursor-pointer hover:bg-light-400">
                              <div className="flex items-center gap-3 text-left ">
                                <div className="h-[30px] w-[30px] flex items-center justify-center bg-[#c3e600] rounded-full">
                                  {userIcon}
                                </div>
                                <span className="">Verified : </span>
                              </div>
                              <div>{totalUsers?.business?.verified}</div>
                            </li>
                            <li className="group md:col-span-3  sm:col-span-6 col-span-12 h-[48px] flex flex-row items-center justify-between px-4 shadow-currency rounded-[10px] cursor-pointer hover:bg-light-400">
                              <div className="flex items-center gap-3 text-left ">
                                <div className="h-[30px] w-[30px] flex items-center justify-center bg-[#c3e600] rounded-full">
                                  {userIcon}
                                </div>
                                <span className="">Unverified : </span>
                              </div>
                              <div>{totalUsers?.business?.unverified}</div>
                            </li>
                          </ul>
                        </div>
                        {/* <div className="py-1">
                          <div className="grid gap-3 grid-cols-12">
                            <div className="py-1 md:col-span-4 sm:col-span-6 col-span-12">
                              <div className="pb-1 font-bold text-left">
                                Agency Status
                              </div>
                              <ul className="list-none pl-0 mb-0 ">
                                {agency_statuses.map(
                                  ({ label, key, bgColor }) => (
                                    <li className="group my-1 h-[48px] flex flex-row items-center justify-between px-4 shadow-currency rounded-[10px] cursor-pointer hover:bg-light-400">
                                      <div className="flex items-center gap-3 text-left ">
                                        <div
                                          style={{ background: bgColor }}
                                          className="h-[30px] w-[30px] flex items-center justify-center bg-[#c3e600] rounded-full text-white"
                                        >
                                          {userIcon}
                                        </div>
                                        <span className="">{label} :</span>{" "}
                                      </div>
                                      <div>
                                        {" "}
                                        {
                                          totalUsers?.business?.agency_status[
                                            key
                                          ]
                                        }
                                      </div>
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                            <div className="py-1 md:col-span-4 sm:col-span-6 col-span-12">
                              <div className="pb-1 font-bold text-left">
                                AML Status
                              </div>
                              <ul className="list-none pl-0 mb-0 ">
                                {aml_statuses.map(({ label, key, bgColor }) => (
                                  <li className="group my-1 h-[48px] flex flex-row items-center justify-between px-4 shadow-currency rounded-[10px] cursor-pointer hover:bg-light-400">
                                    <div className="flex items-center gap-3 text-left ">
                                      <div
                                        style={{ background: bgColor }}
                                        className="h-[30px] w-[30px] flex items-center justify-center bg-[#c3e600] rounded-full  text-white"
                                      >
                                        {userIcon}
                                      </div>
                                      <span className="">{label} :</span>{" "}
                                    </div>
                                    <div>
                                      {" "}
                                      {totalUsers?.business?.aml_status[key]}
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <div className="py-1 md:col-span-4 sm:col-span-6 col-span-12">
                              <div className="pb-1 font-bold text-left">
                                Kyc Status
                              </div>
                              <ul className="list-none pl-0 mb-0 ">
                                {kyc_statuses.map(({ label, key, bgColor }) => (
                                  <li className="group my-1 h-[48px] flex flex-row items-center justify-between px-4 shadow-currency rounded-[10px] cursor-pointer hover:bg-light-400">
                                    <div className="flex items-center gap-3 text-left ">
                                      <div
                                        style={{ background: bgColor }}
                                        className="h-[30px] w-[30px] flex items-center justify-center bg-[#c3e600] rounded-full  text-white"
                                      >
                                        {userIcon}
                                      </div>
                                      <span className="">{label} :</span>{" "}
                                    </div>
                                    <div>
                                      {" "}
                                      {totalUsers?.business?.kyc_status[key]}
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div> */}
                        <div className=" py-1">
                          <div className="pb-1 font-bold text-left">
                            Total Active Users(Balance &gt; 0.1){" "}
                          </div>
                          <ul className="list-none pl-0 mb-0 grid gap-4 grid-cols-12">
                            <li className="group md:col-span-3 sm:col-span-6  col-span-12 h-[48px] flex flex-row items-center justify-between px-4 shadow-currency rounded-[10px] cursor-pointer hover:bg-light-400">
                              <div className="flex items-center gap-3 text-left ">
                                <div className="h-[30px] w-[30px] flex items-center justify-center bg-[#c3e600] rounded-full">
                                  {userIcon}
                                </div>
                                <span className="">Individual :</span>{" "}
                              </div>
                              <div> {activeCount?.totalIndividual}</div>
                            </li>
                            <li className="group md:col-span-3  sm:col-span-6 col-span-12 h-[48px] flex flex-row items-center justify-between px-4 shadow-currency rounded-[10px] cursor-pointer hover:bg-light-400">
                              <div className="flex items-center gap-3 text-left ">
                                <div className="h-[30px] w-[30px] flex items-center justify-center bg-[#c3e600] rounded-full">
                                  {userIcon}
                                </div>
                                <span className="">Business : </span>
                              </div>
                              <div>{activeCount?.totalBusiness}</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="right col-span-12">
                        <div className="flex items-center gap-3 justify-end text-left ">
                          <label
                            htmlFor=""
                            className="form-label m-0 font-bold text-base"
                          >
                            Year
                          </label>
                          <select
                            name=""
                            id=""
                            className="form-select form-control rounded text-xs"
                            value={selectedYear}
                            onChange={(e) => setSelectedYear(e.target.value)}
                          >
                            {yearOptions.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <LineChart data={graphData} year={selectedYear} />
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;

const userIcon = (
  <svg fill="none" height={20} width={20} viewBox="0 0 20 22">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 5.66414C14 7.87203 12.2094 9.66153 10 9.66153C7.79071 9.66153 6.00004 7.87203 6.00004 5.66414C6.00004 3.45625 7.79071 1.66675 10 1.66675C12.2094 1.66675 14 3.45625 14 5.66414Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="square"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6667 20.3213H3.33337C3.33337 19.3745 3.33337 18.4739 3.33337 17.6583C3.33337 15.4491 5.12424 13.6589 7.33337 13.6589H12.6667C14.8758 13.6589 16.6667 15.4491 16.6667 17.6583C16.6667 18.4739 16.6667 19.3745 16.6667 20.3213Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="square"
    ></path>
  </svg>
);
