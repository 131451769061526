import { FC, useState, useRef, useEffect, ChangeEvent } from "react";
import { ArrowDownIcon } from "src/components/icons/arrow-down";
import { SearchIcon } from "src/components/icons/search-icon";
import Dropdown from "src/components/ui/filter-dropdown-list";
import { convertPaymentSystem } from "../../lib/utils";

const statuses = [
  {
    code: "",
    name: "All",
  },
  {
    code: "1",
    name: "Unverified",
  },
  {
    code: "2",
    name: "Verified",
  },
  {
    code: "3",
    name: "Pending",
  },
  {
    code: "4",
    name: "Rejected",
  },
  {
    code: "5",
    name: "Recrossing",
  },
];

const levels = [
  {
    code: "",
    name: "All",
  },
  {
    code: "vip",
    name: "VIP",
  },
  {
    code: "regular",
    name: "Regular",
  },
];

const risk_levels = [
  {
    code: "",
    name: "All",
  },
  {
    code: "low",
    name: "Low",
  },
  {
    code: "medium",
    name: "Medium",
  },
  {
    code: "high",
    name: "High",
  },
];

interface ICurrencyFilter {
  value: string;
  options: { code: string; name: string }[];
  onChange: (value: string) => void;
  // searchText: string;
  // setSearchText: (value: string) => void;
  filterValue: string;
  setFilterValue: (value: string) => void;
}

const CurrencyDrop: FC<ICurrencyFilter> = ({
  value,
  options,
  onChange,
  // searchText,
  // setSearchText,
  filterValue,
  setFilterValue,
}) => {
  const [open, setOpen] = useState(false);
  const node = useRef<HTMLDivElement | null>(null);
  // Temporary

  const handleClick = (event: MouseEvent): boolean | undefined => {
    if (node?.current?.contains(event.target as Node)) {
      return false;
    }

    setOpen(false);
    return true;
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleChange = (selectedValue: string): void => {
    onChange(selectedValue);
    // setSearchText("");
    setFilterValue("");
    setOpen(false);
  };

  const handleSelectToggel = (): void => {
    setOpen((prev) => !prev);
  };

  // const onSearchTextChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   const currentText = e.target.value;
  //   setSearchText(currentText);
  // };

  const selected = options.find((item) => item.code === value);
  console.log(selected, "NAMAAAA");

  return (
    <div className="w-full sm:w-[400px] flex flex-row rounded-[10px] border-[1px] border-light-300">
      <div ref={node} className="flex flex-column relative">
        <button
          type="button"
          className="bg-active rounded-l-[10px] flex items-center justify-center px-2 flex-shrink-0 gap-1"
          onClick={handleSelectToggel}
        >
          <p className="text-[16px] font-manrope font-normal leading-[24px] text-primary">
            {selected?.name}
          </p>
          <ArrowDownIcon />
        </button>
        {open && (
          <div
            className="w-fit absolute bg-white top-[calc(100%+5px)] z-50 p-[10px] rounded-[12px] whitespace-nowrap"
            style={{ boxShadow: "0px 4px 45px 0px rgba(0, 0, 0, 0.25)" }}
          >
            <ul>
              {options.map(({ code, name }) => (
                <li key={code}>
                  <button
                    type="button"
                    className="w-full text-left pt-1 pb-1"
                    onClick={() => handleChange(code)}
                  >
                    {name}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="flex-1 relative bg-white rounded-r-[10px] ">
        {selected?.code === "status" && (
          <Dropdown
            // value={searchText}
            value={filterValue}
            options={statuses}
            // onChange={setSearchText}
            onChange={setFilterValue}
            filterName={selected?.code}
          />
        )}
        {selected?.code === "level" && (
          <Dropdown
            value={filterValue}
            options={levels}
            onChange={setFilterValue}
            filterName={selected?.code}
          />
        )}
        {selected?.code === "risk_level" && (
          <Dropdown
            value={filterValue}
            options={risk_levels}
            onChange={setFilterValue}
            filterName={selected?.code}
          />
        )}
        {
          !["status", "level", "risk_level"].includes(String(selected?.code))
          // && (
          //   <>
          //     <input
          //       className="h-[44px] focus:ring-0 placeholder-other w-full appearance-none text-[16px] font-manrope font-medium rounded-[10px] border-none bg-transparent pl-4 pr-8  text-primary ring-0"
          //       type="text"
          //       placeholder={`Search by ${convertPaymentSystem(
          //         String(selected?.code)
          //       )?.toLowerCase()}`}
          //       value={searchText}
          //       // onChange={onSearchTextChange}
          //     />
          //     <SearchIcon className="absolute right-[8px] top-[14px] w-[16px] h-[16px] text-secondary" />
          //   </>
          // )
        }
      </div>
    </div>
  );
};

CurrencyDrop.displayName = "CurrencyDrop";
export default CurrencyDrop;
