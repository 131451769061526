import { FC, useContext, useEffect, useState } from "react";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { useMutation } from "react-query";
import client from "../../api";
import moment from "moment";
import { AccountIcon } from "../icons/account-icon";
import useAuth from "src/hooks/use-auth";
import { User } from "src/api/types";
import ToggleSwitch from "src/components/ui/switch";
import { NetworkDisconnectMsg, APISomethingWrongMsg } from "src/lib/constants";
import {
  capitalizeFirstLetter,
  formatAddress,
  formatAddressWithoutCountry,
  getKYCStatusName,
  getKYCStatusStyle,
} from "src/lib/utils";
import { getErrorMessage } from "../../lib/utils";
import { Item } from "./user-item";
import Button from "../ui/button";


type UserAccountInfoPageProps = {
  user: User;
};

const UserAccountInfoPage: FC<UserAccountInfoPageProps> = ({ user }) => {
  const { setIsAlertOpened, setAlertText,  setKey, key } = useContext(GlobalContext);
  const isMounted = useIsMounted();

  const { mutate: updateVip, isLoading: isLoadingVip } = useMutation(
    user.is_business
      ? client.users.updateBuisinessVip
      : client.users.updateIndividualVip,
    {
      onSuccess: (data) => {
        user.is_vip = data.is_vip;
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const getStatusColor = (status: string) => {
    switch (status) {
      case "verified":
      case "approved": {
        return "#03BC70";
      }
      case "unverified":
      case "rejected": {
        return "#F44336";
      }
      default:
        return "";
    }
  };


  const { mutate:updateStatus , isLoading } = useMutation(
    client.users.updateIndividualAgencyStatus,
  {
    onSuccess: (data) => {
    // {  console.log(data,"DATAAAA")}
      setAlertText(data?.message);
      setIsAlertOpened(true);
      setKey( key+1 );
      
    },
    onError: (error: any) => {
      if (error.code === "ERR_NETWORK") {
        setAlertText(NetworkDisconnectMsg);
      } else {
        if (error.response) {
          setAlertText(getErrorMessage(error.response.data));
        } else {
          setAlertText(APISomethingWrongMsg);
        }
      }
      setIsAlertOpened(true);
    },
  }
);

  const individualFields = (
    <div className="grid grid-cols-3 mt-6">
      <div>
        <div className="grid grid-cols-3">
          <div className="flex flex-col gap-6">
            <div>
              <p className="text-secondary font-manrope text-[14px] font-medium w-[100px] sm:w-[140px] flex-shrink-0">
                Level:
              </p>
              <div className="flex items-center gap-3">
                <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
                  VIP
                </p>
                <ToggleSwitch
                  type="vip"
                  value={!!user.is_vip}
                  onValueChanged={() =>
                    updateVip({
                      userId: user.id,
                      input: { is_vip: !user.is_vip ? 1 : 0 },
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 mt-6">
          <div className="flex flex-col gap-6">
            <div>
              <p className="text-secondary font-manrope text-[14px] font-medium w-[100px] sm:w-[140px] flex-shrink-0">
                KYC status:
              </p>
              <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
                <span style={{ color: getStatusColor(user.kyc_status?.name) }}>
                  {user.kyc_status?.name === "unverified"
                    ? "Not verified"
                    : capitalizeFirstLetter(
                        user.kyc_status?.name.replaceAll("_", " ")
                      )}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 mt-6">
          <div className="flex flex-col gap-6">
            <div>
              <p className="text-secondary font-manrope text-[14px] font-medium w-[100px] sm:w-[140px] flex-shrink-0">
                AML Status:
              </p>
              <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
                <span style={{ color: getStatusColor(user.status?.name) }}>
                  {user.status?.name === "unverified"
                    ? "Not verified"
                    : capitalizeFirstLetter(
                        user.status?.name.replaceAll("_", " ")
                      )}
                </span>
              </p>
            </div>
          </div>
        </div>
        {user.kyc_status?.name === "unverified" ||
        String(user.data.country).toLowerCase() !== "georgia" ? (
          ""
        ) : (
          <div className="grid grid-cols-3 mt-6">
            <div className="flex flex-col gap-6">
            <div>
              <p className="text-secondary font-manrope text-[14px] font-medium w-[100px] sm:w-[140px] flex-shrink-0">
                Agency Status:
              </p>
              <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
                {user?.agency_status ? (
                  <span
                    style={{ color: getStatusColor(user.agency_status?.name) }}
                  >
                    {user.agency_status?.name === "unverified"
                      ? "Not verified"
                      : capitalizeFirstLetter(
                          user.agency_status?.name.replaceAll("_", " ")
                        )}
                  </span>
                ) : (
                  ""
                )}
              </p>
              <div>
                {user?.kyc_status?.name === "approved" && user?.agency_status?.name === "unverified" && (
                  <Button
                    variant="validate"
                    type="submit"
                    isLoading={isLoading}
                    className="w-full rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] h-[45px]"
                    onClick={() => updateStatus({
                      id: user?.id
                    })}
                  >
                   {!isLoading && "Verify"} 
                  </Button>
                 )}
              </div>
            </div>
            </div>
          </div>
        )}

        <Item title="Risk Level" value={user.risk_level} />
      </div>
      <div>
        <Item title="First Name" value={user.data.first_name} />
        <Item title="Last Name" value={user.data.last_name} />
        <Item
          title="Address"
          value={`${user.data?.post_code ? `${user.data?.post_code},` : ""}${
            user.data?.city ? `${user.data?.city},` : ""
          }${user.data?.street || ""}`}
        />
        <Item title="Country" value={user.data?.country} />
      </div>
      <div>
        <Item title="Phone Number" value={user.data?.phone} />
        <Item title="Date of Birth" value={user.data?.date_of_birth} />
        <Item title="Email" value={user.email} />
      </div>
    </div>
  );

  const buisinessFields = (
    <div className="grid grid-cols-3 mt-6">
      <div>
        <div className="grid grid-cols-3">
          <div className="flex flex-col gap-6">
            <div>
              <p className="text-secondary font-manrope text-[14px] font-medium w-[100px] sm:w-[140px] flex-shrink-0">
                Level:
              </p>
              <div className="flex items-center gap-3">
                <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
                  VIP
                </p>
                <ToggleSwitch
                  type="vip"
                  value={!!user.is_vip}
                  onValueChanged={() =>
                    updateVip({
                      userId: user.id,
                      input: { is_vip: !user.is_vip ? 1 : 0 },
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 mt-6">
          <div className="flex flex-col gap-6">
            <div>
              <p className="text-secondary font-manrope text-[14px] font-medium w-[100px] sm:w-[140px] flex-shrink-0">
                KYC status:
              </p>
              <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
                <span style={{ color: getStatusColor(user.kyc_status?.name) }}>
                  {user.kyc_status?.name === "unverified"
                    ? "Not verified"
                    : capitalizeFirstLetter(
                        user.kyc_status?.name.replaceAll("_", " ")
                      )}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 mt-6">
          <div className="flex flex-col gap-6">
            <div>
              <p className="text-secondary font-manrope text-[14px] font-medium w-[100px] sm:w-[140px] flex-shrink-0">
                AML Status:
              </p>
              <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
                <span style={{ color: getStatusColor(user.status?.name) }}>
                  {user.status?.name === "unverified"
                    ? "Not verified"
                    : capitalizeFirstLetter(
                        user.status?.name.replaceAll("_", " ")
                      )}
                </span>
              </p>
            </div>
          </div>
        </div>
        <Item
          title="Director's first name"
          value={user.aml_business_data?.director_first_name}
        />
        <Item
          title="Director's last name"
          value={user.aml_business_data?.director_last_name}
        />
      </div>
      <div>
        <Item
          title="Director's TIN"
          value={user.aml_business_data?.director_tin}
        />
        <Item
          title="Legal address"
          value={user.aml_business_data?.legal_address}
        />
        <Item
          title="Actual address"
          value={user.aml_business_data?.real_address}
        />
        <Item title="Phone Number" value={user.data?.phone} />
      </div>
      <div>
        <Item title="Tax code" value={user.aml_business_data?.tax_code} />
        <Item title="Date of Birth" value={user.data?.date_of_birth} />
        <Item title="Email" value={user.email} />
        <Item
          title="Company name"
          value={user.aml_business_data?.company_name}
        />
      </div>
    </div>
  );

  return (
    <div className="bg-white rounded-r-[12px] rounded-bl-[12px] w-full relative">
      <div className="p-4 relative">
        <div className="flex flex-row items-center justify-between w-full border-b-2 border-gray border-opacity-50 gap-2 pb-4">
          <p className="text-primary font-manrope text-[18px] font-medium leading-[28px]">
            User data
          </p>
          <div className="flex gap-2 items-center">
            <span className="text-secondary font-manrope text-[14px] flex-shrink-0 font-semibold">
              Type account:
            </span>
            <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
              {user.is_business ? "Buisiness account" : "Individual"}
            </p>
          </div>
        </div>
        {user.is_business ? buisinessFields : individualFields}
      </div>
    </div>
  );
};

export default UserAccountInfoPage;
